<template>
  <div class="page-content">
    <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
      <div class="panel-body">
        <h1 class="page-header">
          工业企业列表 <small>工业企业详细信息编辑...</small>
        </h1>
        <vxe-toolbar ref="xToolbar" :loading="loading" custom zoom print>
          <template #buttons>
            <vxe-input style="width: 150px" v-model="BusinessName" size="mini" type="text" placeholder="请输入公司名称查询"
              @keyup.enter.native="BusinessIdkeyupEvent">
            </vxe-input>
            <vxe-select clearable v-model="industryId.value" placeholder="所属行业" size="mini" type="text"
              :options="industryId" transfer></vxe-select>
            <vxe-select clearable v-model="GridId.value" size="mini" placeholder="网格长" type="text" :options="GridId"
              transfer></vxe-select>
            <vxe-select clearable v-model="Region.value" size="mini" placeholder="所属区域" type="text" :options="Region"
              transfer class="m-r-5"></vxe-select>
            <!-- <vxe-button
              icon="fa fa-search"
              status="primary"
              content="新增"
              @click="BusinessIdkeyupEvent"
              >查询</vxe-button
            > -->
            <vxe-button icon="fa fa-search" @click="BusinessIdkeyupEvent" circle></vxe-button>
            <vxe-button status="info" icon="fa fa-search" size="mini" @click="clearEvent()">清除筛选</vxe-button>
            <vxe-button status="warning" icon="fa fa-trash-alt" size="mini" @click="getRemoveEvent()">删除选中</vxe-button>
            <vxe-button size="mini" icon="fa fa-plus" status="primary" content="新增" @click="insertEvent"></vxe-button>
            <vxe-button icon="fa fa-download" size="mini" @click="getAlldata(0)">导出</vxe-button>
            <!-- <vxe-button @click="importDataEvent">导入</vxe-button> -->
            <!-- <vxe-button @click="printEvent">打印</vxe-button> -->
          </template>
        </vxe-toolbar>
        <vxe-table re round border :loading="loading" :height="tabelHeight" :data="tableData" :seq-config="{
          startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
        }" ref="xTable" :row-config="{ height: 60 }" :print-config="{}" :export-config="{}"
          :column-config="{ resizable: true }">
          <vxe-column type="checkbox" width="50" fixed="left" align="center"></vxe-column>
          <vxe-column title="序号" type="seq" width="100" fixed="left" align="center"></vxe-column>
          <vxe-column field="img" type="html" title="二维码" width="70">
            <template #default="{ row }">
              <a href="#" @click="editEvent(row)">
                <img :src="baseUrl + '/api/business/qrcode?id=' + row.id" alt="" /></a>
            </template>
          </vxe-column>
          <vxe-column field="regionName" title="所属区域" width="100"></vxe-column>
          <vxe-column field="gridName" title="网格长" width="80"></vxe-column>
          <vxe-column title="公司名称" align="left" field="name" show-overflow min-width="250">
            <!-- <template #default="{ row }">
              <a href="#" @click="editEvent(row)"> {{ row.name }} </a>
            </template> -->
          </vxe-column>
          <vxe-column title="同一社会信用代码" field="creditCode" width="190"></vxe-column>
          <vxe-column title="法人代表" field="legal" width="120"></vxe-column>
          <vxe-column title="联系电话" field="legalTel" width="120"></vxe-column>
          <vxe-column title="所属行业" width="200" field="industryName"></vxe-column>
          <vxe-column title="地址" width="200" :visible="false" field="address"></vxe-column>
          <vxe-column title="最后检查时间" width="200" field="lastModificationTime">
            <template #default="{ row }">
              <span>{{ formattime(row.lastModificationTime) }}</span>
            </template>
          </vxe-column>
          <vxe-column title="操作" fixed="right" width="210">
            <template #default="{ row }">
              <vxe-button size="mini" status="primary" icon="fa fa-eye" @click="editEvent(row)">企业信息
              </vxe-button>
              <!-- <vxe-button
                    size="small"
                    status="primary"
                    @click="SecurityCheck(row)"
                    >安全检查
                  </vxe-button>
                  <vxe-button
                    size="small"
                    status="primary"
                    @click="editEvent(row)"
                    >安全培训
                  </vxe-button> -->
              <vxe-button size="mini" status="warning" icon="fa fa-trash-alt"
                @click="removeRowEvent(row, row.id)">删除</vxe-button>
            </template>
          </vxe-column>
        </vxe-table>
        <vxe-pager border icon-prev-page="fa fa-angle-left" icon-jump-prev="fa fa-angle-double-left"
          icon-jump-next="fa fa-angle-double-right" icon-next-page="fa fa-angle-right" icon-jump-more="fa fa-ellipsis-h"
          :loading="loading" :current-page="tablePage.currentPage" :page-size="tablePage.pageSize"
          :total="tablePage.totalResult" :layouts="[
            'PrevPage',
            'JumpNumber',
            'NextPage',
            'FullJump',
            'Sizes',
            'Total',
          ]" @page-change="handlePageChange">
        </vxe-pager>
      </div>
    </div>
  </div>
</template>
<script>
import { businessApi } from "@/api/system/businessApi";
import { dictApi } from "@/api/system/dictApi";
import { propertyService } from "@/api/utils/request";
import CompanyInfomation from "../Infomation.vue"; //导入企业信息编辑跳出框
import DeleteRemarks from "../DeleteRemarks.vue"; //跳出删除备注编辑跳出框
// 打印样式
const printStyle = `
        .title {
          text-align: center;
        }
        .my-list-row {
          display: inline-block;
          width: 100%;
        }
        .my-list-col {
          float: left;
          width: 33.33%;
          height: 28px;
          line-height: 28px;
        }
        .my-top,
        .my-bottom {
          font-size: 12px;
        }
        .my-top {
          margin-bottom: 5px;
        }
        .my-bottom {
          margin-top: 30px;
          text-align: right;
        }
        `;
// 打印顶部内容模板
const topHtml = `
        <h1 class="title">东钱湖企业安全码</h1>
        `;
// 打印底部内容模板
const bottomHtml = `
        <div class="my-bottom">
          <div class="my-list-row">
            <div class="my-list-col"></div>
            <div class="my-list-col">创建人：   </div>
            <div class="my-list-col">创建日期：2020-09-20</div>
          </div>
        </div>
        `;
export default {
  data() {
    return {
      BusinessName: "",
      tabelHeight: document.body.clientHeight - 300,
      loading: false,
      tableData: [],
      industryId: [],
      GridId: [],
      Region: [],
      DeleteId: [],
      tablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
      // exportConfig: {
      //   remote: true,
      //   exportMethod: this.exportDataEvent,
      //   types: ["csv", "xml", "txt"],
      //   modes: ["current", "selected", "all"],
      //   data:
      // },
      list: [],
      // importConfig: {
      //   remote: true,
      //   importMethod: this.importMethod,
      //   types: ["csv", "html", "xml", "txt"],
      //   modes: ["insert"],
      // },
      formattime(time) {
        if (time) {
          return time.slice(0, 10);
        } else {
          return "";
        }
      },
    };
  },

  watch: {
    $route(to) {
      if (to.path == "/CompanyList") {
        this.GetCompanyData();
      }
    },
  },
  created() { },
  mounted() {
    // 页面初始化时运行
    // console.log(this.$route.query.id);
    this.getindustryId();
    this.getRegion();
    this.getGridId();
    this.GetCompanyData();
    // this.getAlldata(0);
    let that = this;
    window.onresize = () => {
      return (() => {
        that.tabelHeight = document.body.clientHeight - 300;
      })();
    };
  },

  methods: {
    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "删除成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },
    //进入企业编辑页面
    editEvent(row) {
      this.$router.push({
        path: "/CompanyInfo?id=" + row.id,
        meta: {
          title: row.name, // 页签标题
        },
      });
    },
    // 进入安全检查列表
    SecurityCheck(row) {
      this.$router.push({
        path: "/SecurityCheckList?id=" + row.id,
        meta: {
          title: row.name, // 页签标题
        },
      });
    },

    //获取企业列表数据
    GetCompanyData() {
      let that = this;
      that.loading = true;
      businessApi
        .GetCompanyData({
          SkipCount: (that.tablePage.currentPage - 1) * that.tablePage.pageSize,
          MaxResultCount: that.tablePage.pageSize,
          Sorting: "",
          Filter: this.BusinessName.replace(/^\s+|\s+$/g, ""),
          CreditCode: "",
          Legal: "",
          LegalTel: "",
          RegionId: this.Region.value,
          GridId: this.GridId.value,
          IndustryId: this.industryId.value,
          State: 0,
          Menu: 0,
        })
        .then((res) => that.GetSuccess(that, res))
        .finally(() => {
          that.loading = false;
        });
    },
    getAlldata(i) {
      let that = this;
      businessApi
        .GetCompanyData({
          SkipCount: i * 1000,
          MaxResultCount: 1000,
          Sorting: "",
          Filter: this.BusinessName.replace(/^\s+|\s+$/g, ""),
          CreditCode: "",
          Legal: "",
          LegalTel: "",
          RegionId: this.Region.value,
          GridId: this.GridId.value,
          IndustryId: this.industryId.value,
          State: 0,
          Menu: 0,
        })
        .then((res) => {
          that.list.push(res.data.items);
          that.list = that.list.flatten();
          if (that.list.length < res.data.totalCount) {
            that.getAlldata(i + 1);
          }
          if (res.data.totalCount == that.list.length) {
            that.exportDataEvent();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    exportDataEvent() {
      let that = this;
      this.loading = true;
      let options = [];
      setTimeout(() => {
        let list = that.list;
        // that.list.forEach((element) => {
        //   options.push({
        //     name: element.name,
        //     gridName: element.gridName,
        //     regionName: element.regionName,
        //     creditCode: element.creditCode,
        //     legal: element.legal,
        //     legalTel: element.legalTel,
        //     industryName: element.industryName,
        //   });
        // });
        console.log(options);
        const $table = this.$refs.xTable;
        $table.openExport({
          types: ["csv", "xml", "txt"],
          modes: ["all"],
          filename: "工业企业数据导出",
          type: "csv",
          columnFilterMethod({ column }) {
            return column.field;
          },
          isHeader: true,
          isFooter: true,
          data: list,
        });
        that.list = [];
        this.loading = false;
      }, 100);
    },
    // 获取成功
    GetSuccess(that, res) {
      if (res.success) {
        //获取数据成功后数据传到Data里给页面赋值

        that.tableData = res.data.items;
        that.tablePage.totalResult = res.data.totalCount;
        // that.updateTableImgSrc();
      } else {
        console.log(res);
      }
    },

    // 删除企业信息
    removeRowEvent(row, id) {
      this.$modal.show(
        DeleteRemarks,
        { id: id },
        { draggable: true },
        { "before-close": this.removeRowbeforeClose }
      );
    },
    //跳出框关闭是返回事件
    removeRowbeforeClose(event) {
      this.GetCompanyData();
      console.log("before-close", event);
    },

    //键盘他抬起事件，用于列表查询
    BusinessIdkeyupEvent() {
      this.tablePage.currentPage = 1;
      this.GetCompanyData();
    },
    clearEvent() {
      (this.BusinessName = ""),
        (this.Region.value = ""),
        (this.GridId.value = ""),
        (this.industryId.value = ""),
        (this.tablePage.currentPage = 1);
      this.GetCompanyData();
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.GetCompanyData();
    },

    // 进入企业新增页面
    async insertEvent() {
      this.$modal.show(
        CompanyInfomation, //组件引用页面
        { Companyid: this.$route.query.id }, //入参值
        { height: "750", width: "70%", draggable: true }, //跳出框参数
        {
          "before-close": this.beforeClose, //跳出框关闭是返回事件
        }
      );
      // this.$router.push({ path: "/CompanyInfo?id=" });
    },
    //跳出框关闭是返回事件
    beforeClose(event) {
      this.GetCompanyData();
      console.log("before-close", event);
    },
    getRemoveEvent() {
      const $table = this.$refs.xTable;
      const CheckboxRecords = $table.getCheckboxRecords();
      if (CheckboxRecords.length == 0) {
        return false;
      }
      let options = [];
      CheckboxRecords.forEach(function (item) {
        options.push(item.id);
      });
      this.DeleteId = options;
      this.removeRowEvent([], this.DeleteId);
      console.log(CheckboxRecords);
    },
    // // 表格导入数据方法
    // importDataEvent() {
    //   const $table = this.$refs.xTable.value;
    //   $table.importData();
    //   // this.$refs.xTable.importData({ types: ["xlsx"] });
    // },
    printEvent() {
      this.$refs.xTable.print({
        sheetName: "打印表格",
        style: printStyle,
        columns: [
          { type: "seq" },
          { field: "regionName" },
          { field: "gridName" },
          { field: "name" },
          { field: "creditCode" },
          { field: "legal" },
          { field: "legalTel" },
          { field: "industryName" },
        ],
        beforePrintMethod: ({ content }) => {
          // 拦截打印之前，返回自定义的 html 内容
          return topHtml + content + bottomHtml;
        },
      });
    },
    beforePrintMethod: ({ content }) => {
      // 拦截打印之前，返回自定义的 html 内容
      return topHtml + content + bottomHtml;
    },

    // 导入
    importMethod({ file }) {
      // 处理表单
      const formBody = new FormData();
      formBody.append("file", file);
      // 上传文件
      return propertyService({
        url: "/api/inportdata/business", //文件上传Api
        method: "post",
        data: formBody, // Api提交数据部分
        needToken: true,
        headers: {
          "content-type": "multipart/form-data",
        },
      })
        .then((rest) => {
          if (rest.status === 200) {
            this.$refs.grid.commitProxy("query");
            this.$message({
              type: "success",
              message: "导入成功",
            });
          } else {
            this.$message({
              type: "error",
              message: "导入失败",
            });
          }
        })
        .catch(() => {
          this.$message({
            content: "导入失败，请检查数据是否正确！",
            status: "error",
          });
        });
    },
    // 获取所属行业数据字典
    getindustryId() {
      let that = this;
      let options = [];
      that.industryId = [];
      dictApi
        .getDictUrl({
          id: "3a021d7b-6c1b-98f5-f8cf-9404f68574a6",
          bChild: false,
        })
        .then((res) => {
          // console.log('readSpecialEquipmentDict',res.data)
          res.data.forEach((element) => {
            options.push({
              label: element.name,
              value: element.id,
            });
          });
          that.industryId = options;
        });
    },
    // 获取网格长数据字典
    getGridId() {
      let that = this;
      let options = [];
      that.gridheadEquipmentDict = [];
      businessApi
        .GetUsersList({
          SkipCount: 1,
          MaxResultCount: 100,
          Sorting: "",
          Filter: "",
          RoleId: "2",
        })
        .then((res) => {
          res.data.items.forEach((element) => {
            options.push({
              value: element.id,
              label: element.name,
            });
          });
          that.GridId = options;
        });
    },
    getRegion() {
      let that = this;
      let options = [];
      that.Region = [];
      dictApi
        .getDictUrl({
          id: "3a019cfc-ef88-6b72-7bc4-f7c04aa3e94c",
          bChild: false,
        })
        .then((res) => {
          // console.log('readSpecialEquipmentDict',res.data)
          res.data.forEach((element) => {
            options.push({
              label: element.name,
              value: element.id,
            });
          });
          that.Region = options;
        });
    },
  },
};
</script>

<style scoped>
.vxe-cell img {
  width: 40px;
}

.vxe-body--column,
.vxe-body--column {
  padding: 0;
}
</style>
