<template>
  <div class="card border-0">
    <div class="card-header f-w-600">备注</div>
    <div class="card-body height-auto m-0 p-0">
      <form v-on:submit="removeRowEvent(id)" method="POST">
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <!-- panel-body begin -->
          <div class="panel-body">
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-4 col-form-label text-right"
                >备注：</label
              >
              <div class="col-lg-9 col-md-8">
                <textarea
                  class="form-control col-lg-12 col-md-8"
                  rows="6"
                  data-parsley-range="[5,10]"
                  v-model="remarks"
                  required
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="card-footer text-right f-w-600">
        <button
          class="btn btn-sm btn-success m-r-10"
          type="submit"
          @click="removeRowEvent(id)"
        >
          <i class="fa fa-save"></i> 确定
        </button>
        <button
          type="button"
          @click="$emit('close')"
          class="btn btn-sm btn-grey m-r-10"
        >
          ❌关闭
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { businessApi } from "@/api/system/businessApi";
export default {
  props: ["id"],
  data() {
    return {
      remarks: "",
    };
  },
  mounted() {},
  methods: {
    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "删除成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },
    ToastWarning(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "False") {
        title = "保存出错";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "warning",
        title: title,
      });
    },

    // 删除企业信息
    removeRowEvent(id) {
      if (this.remarks == "") {
        this.$swal({
          icon: "warning",
          text: "请填写删除理由！",
          type: "info",
          // showCancelButton: true, //是否显示关闭按钮
          buttonsStyling: false,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          confirmButtonClass: "btn m-r-5 btn-" + "info" + "", //按钮颜色设置
          cancelButtonClass: "btn btn-default", //按钮类型设置
        });
      } else {
        this.$swal({
          // title: "Are you sure?",
          icon: "warning",
          text: "是否确定删除?",
          type: "info",
          showCancelButton: true, //确定按钮
          buttonsStyling: false,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          confirmButtonClass: "btn m-r-5 btn-" + "info" + "", //按钮颜色设置
          cancelButtonClass: "btn btn-default", //按钮类型设置
        }).then((isConfirm) => {
          try {
            //判断 是否 点击的 确定按钮
            if (isConfirm.value) {
              let that = this;
              if (id) {
                businessApi
                  .DeleteCompany({ ids: id.toString(), remarks: this.remarks })
                  .then((res) => that.DeleteCompanySuccess(that, res))
                  .finally(() => {});
              }
            } else {
              console.log(isConfirm);
            }
          } catch (e) {
            alert(e);
          }
        });
      }
    },
    DeleteCompanySuccess(that, res) {
      if (res.success) {
        this.$emit("close");
        that.ToastSuccess(res);
      } else {
        console.log(res);
      }
    },
  },
};
</script>
